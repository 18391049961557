import React from 'react';
import { File } from '@model/generated/index';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '@components/global/Layout';
import { RouteEnum } from '@model/route.enum';
import { GatsbyImage } from 'gatsby-plugin-image';
import { InstagramIcon } from '@components/global/InstagramIcon';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image11: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "info/image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image11: file(relativePath: { eq: "info/image11.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout
      title="Wunderkammer"
      navigationItems={[
        { title: 'Projekt', to: RouteEnum.INFO },
        { title: 'Termine', to: RouteEnum.EVENT },
        { title: 'Förderung', to: RouteEnum.SPONSORD },
      ]}
    >
      <div className="flex">
        <div className="flex-auto">
          <GatsbyImage
            className="w-full"
            objectFit="cover"
            image={data.image11.childImageSharp.gatsbyImageData}
            alt="Wunderkammer der Zukunft"
          />
          <div className="flex flex-col">
            <GatsbyImage
              className="-mt-92 self-end mr-32 w-1/2"
              objectFit="contain"
              image={data.image1.childImageSharp.gatsbyImageData}
              alt="Wunderkammer der Zukunft"
            />
            <div className="flex-auto p-32">
              <h3>Künftige Ausstellungen</h3>
              <div>
                Demnächst
                <br />
                <b>Digital Diary - Objekte der Alltagswelten</b>
                <p>
                  Viele historische Museen in Deutschland modernisieren ihre
                  Dauerausstellungen, indem sie ihre Bestände und die großen
                  Geschichten, die durch diese zu erzählen sind, auf deren
                  Relevanz für Prozesse und Entwicklungen der eigenen Gegenwart
                  und Zukunft befragen. Das ist nicht neu. Aber in der aktuellen
                  museums­wissen­schaftlichen Diskussion zeigt sich ein sehr
                  tief gehendes Bedürfnis der Menschen, ihre Gegenwart und
                  aktuellen Lebens­umstände in ein Kontinuum von Vergangenem und
                  Zukünftigem einzu­ordnen.
                </p>
                <p>
                  Sammeln, Bewahren, Erforschen, Präsentieren, Vermitteln – das
                  sind nach inter­nationalem ICOM-Standard die Kern­aufgaben
                  eines Museums. Im Bereich der Frei­zeit­gestaltung findet
                  heute ein intensiver Wettbewerb zwischen den verschiedenen
                  Anbietern statt. Museen müssen konkurrieren mit Theatern und
                  Freizeitparks, Kinos und Festi­vals. Museen weisen hier ein
                  einzigartiges Alleinstellungsmerkmal auf: Sie sind Orte der
                  Originale. Es gilt, diese Objekte zum Sprechen zu bringen –
                  fachlich korrekt, ziel­gruppen­spezifisch und mit einem
                  Höchstmaß an Nachhaltigkeit.
                </p>
                <p>
                  Das Projekt <b>„Digital Diary“</b> wird
                  Objekte­/Dokumente­/Fotos­/Filme unter Einschluss der mit
                  ihnen in Verbindung stehenden Persön­lich­keiten auf den
                  Prüfstand stellen. Wie relevant sind die Objekte für ein
                  breites Publikum, erregen sie Aufsehen, regen sie an zur
                  Diskussion und kritischer Auseinandersetzung, haben sie sogar
                  Streitpotenzial? Besitzen sie die Eigenschaften, starke
                  Signale auszusenden, eine Botschaft zu vermit­teln,
                  Erken­ntnis­interesse zu erzeugen? Haben die Objekte eine
                  rationale und emotionale Seite, speichern sie Wissen und
                  berühren sie zugleich die Sinne? Diese Fragen werden gestellt
                  und sollen beantwortet werden – im digitalen und im ganz
                  direkten Dialog mit der Stadt- und Regional­gesellschaft.
                </p>
              </div>
              <h3>Bisherige Ausstellungen</h3>
              <div>
                1. bis 24. Dezember 2021
                <br />
                <b>
                  <a href={RouteEnum.PROJECTS_7}>1 Min Audiovisual Joy</a>
                </b>
                <br />
                Audiovisueller Adventskalender von Laurenz Theinert
                <div className="pt-8">
                  ab Einbruch der Dunkelheit
                  <br />
                  außerhalb der Öffnungszeiten von außen einsehbar
                </div>
              </div>

              <p className="pt-32">
                17. Dezember 2021 von 19 bis 22 Uhr
                <br />
                <b>
                  <a href={RouteEnum.PROJECTS_6}>Fensterprosa</a>
                </b>
                <br />
                Beschriftung der Stühle aus dem Lönneberga mit Vera Vorneweg im{' '}
                <a
                  href="https://meet.jit.si/antennetanne"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live-Stream
                </a>
                <br />
              </p>

              <div className="pt-32">
                21. November bis 26. Dezember 2021
                <br />
                <b>
                  <a
                    href="https://meet.jit.si/antennetanne"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Yonder
                  </a>
                </b>
                <br />
                Ein Videoprojekt von Emilia Forstreuter
                <div className="pt-8">
                  auf der Videowall
                  <br />
                  mittwochs - samstags von 18 bis 24 Uhr
                </div>
              </div>

              <div className="pt-32">
                17. Juli bis 3. Oktober 2021
                <br />
                <b>
                  <a href={RouteEnum.PROJECTS_3}>Reset the Forest</a>
                </b>
                <br />
                Künstlerische Intervention für den Wald von Katharina Berndt,
                Tom Groll und Kuno Seltmann
              </div>

              <div className="pt-32">
                17. bis 26. September 2021
                <br />
                <b>
                  <a href={RouteEnum.PROJECTS_4}>Wortfindungsamt</a>
                </b>
                <br />
                Ein partizipatives Kunstprojekt von Sigrid Sandmann
                <div className="pt-8">
                  Abweichender Standort:
                  <br />
                  Rathausplatz - Vor der Hauptpost
                  <br />
                  Lüdenscheid
                </div>
              </div>

              <p className="pt-32">
                9. September 2021
                <br />
                <b>Vom Forst zum Wald</b>
                <br />
                Diskussionsveranstaltung mit Wilhelm Bode, Stralsund
              </p>
              <p className="pt-32">
                5. Juni bis 8. August 2021
                <br />
                <b>
                  <a href={RouteEnum.PROJECTS_2}>
                    Entwürfe für Schwerelosigkeit / Hybrid11
                  </a>
                </b>
                <br />
                Ortsspezifische, audiovisuelle Maschinen-Installation der
                Künstlergruppe RaumZeitPiraten
              </p>
              <p className="pt-32">
                5. Juni 2021
                <br />
                Eröffnung der <b>WorldWideWunderkammer</b> in der{' '}
                <b>Wunderkammer der Zukunft</b> mit den RaumZeitPiraten und
                Robert Sochacki
              </p>

              <h3>Öffnungszeiten</h3>
              <p>Mittwoch bis Sonntag, 11 bis 18 Uhr</p>
              <h3>Standort</h3>
              <div>
                Museen der Stadt Lüdenscheid
                <br />
                Sauerfelder Straße 14-20
                <br />
                58511 Lüdenscheid
                <br />
                <br />
                <div className="pb-4">
                  <InstagramIcon />
                </div>
                Tel.: <a href="tel:+492351 17 1496">02351/17-1496</a>
                <br />
                e-Mail:{' '}
                <a href="mailto:wunderkammer@luedenscheid.de">
                  wunderkammer@luedenscheid.de
                </a>
              </div>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Termine</h1>
      </div>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
